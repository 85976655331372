import { createContext, useState } from 'react';
import axios from 'axios';

import { API_BASE_URL } from '../../config/baseURLs';

const FranchisedBrandsContext = createContext();

export const FranchisedBrandsProvider = ({ children }) => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [formValues, setFormValues] = useState({
		companyLogo: null,
		authorizationLetter: null,
		corpPresentation: null,
		sortDescription: '',
		longDescription: '',
		headquaters: null,
		categories: null,
		resources: [{ resourceName: null, resourceFile: null }],
	});

	const filterUniqueIdsAndRecent = (arr) => {
		const uniqueIdsMap = new Map();

		arr?.forEach((obj) => {
			if (obj.ID) {
				const currentId = obj.id;
				const existingObj = uniqueIdsMap.get(currentId);

				if (
					!existingObj ||
					new Date(obj.updatedAt) > new Date(existingObj.updatedAt)
				) {
					uniqueIdsMap.set(currentId, obj);
				}
			}
		});

		return Array.from(uniqueIdsMap.values());
	};

	const getData = () => {
		setIsLoading(true);
		axios
			.get(`${API_BASE_URL}/franchised`)
			.then((response) => {
				setData(response.data?.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const extractFileName = (link) => {
		const file = link?.split('/')?.pop();
		const name = file?.split('_')?.slice(1)?.join('_');

		return decodeURIComponent(name);
	};

	return (
		<FranchisedBrandsContext.Provider
			value={{
				getData,
				data,
				setData,
				isLoading,
				error,
				formValues,
				setFormValues,
				extractFileName,
				filterUniqueIdsAndRecent,
			}}
		>
			{children}
		</FranchisedBrandsContext.Provider>
	);
};

export default FranchisedBrandsContext;
