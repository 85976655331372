import {
	memo,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { TbSpeakerphone } from 'react-icons/tb';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { QuestionCircleOutlined } from '@ant-design/icons';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import InnerHeader from '../../components/innerHeader';
import CustomerOutreachContext from '../../context/customerOutreachContext';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import { isNotEmptyArray, titleCase } from '../../utils';
import NoData from '../../components/nodata';
import NotificationBar from '../../components/notificationBar';
import EdenContext from '../../context/edenContext';
import Select from '../../components/select';
import MailConfig from './MailConfig';
import Actions from './Actions';
import MailPreview from '../../components/MailPreview';
import ToolTip from '../../components/tooltip';
import { dateFilterParams, dollarDecimalFilterParams } from '../../filters';
import Button from '../../components/button';
import Popconfirm from '../../components/popConfirm';
import { CountryCellRenderer } from '../../columns/countryCellRenderer';
import { COUNTRY_CODES } from '../../columns';
import { useQuery } from '../../hooks/use-query';

import styles from './customerOutreach.module.scss';

// Custom comparator for sorting filter values
const customComparator = (a, b) => {
	const specialOrder = ['(Select All)', '(Blanks)', 'Today', 'Tomorrow'];

	// Handle special cases
	const aIndex = specialOrder.indexOf(a);
	const bIndex = specialOrder.indexOf(b);

	if (aIndex > -1 && bIndex > -1) {
		return aIndex - bIndex;
	} else if (aIndex > -1) {
		return -1; // a comes first
	} else if (bIndex > -1) {
		return 1; // b comes first
	}

	// Handle nulls (blanks) - Push them to the end
	if (a === null || a === undefined || a === '(Blanks)') return 1;
	if (b === null || b === undefined || b === '(Blanks)') return -1;

	// Parse numeric day values from strings like '1 Day', '2 Days'
	const aDays = parseInt(a?.split(' ')[0]);
	const bDays = parseInt(b?.split(' ')[0]);

	return aDays - bDays;
};

const labelFormatter = (value) => {
	if (value === 0) {
		return 'Today';
	} else if (value === 1) {
		return '1 Day';
	} else if (value > 1) {
		return `${value} Days`;
	} else if (value === -1) {
		return 'Tomorrow';
	} else if (value < -1) {
		return `${Math.abs(value)} Days Ahead`;
	}
	return value;
};

const CustomerOutreach = () => {
	const {
		customerSummary,
		customerOutreach,
		showCustomerSummary,
		showCustomerOutreach,
		getData,
		data,
		isLoading,
		error,
		sendMailCount,
		setSendMailCount,
		contactInfoUpdate,
		accounts,
		selectedRows,
		setSelectedRows,
	} = useContext(CustomerOutreachContext);

	const { messageURl, userType, userMail, userInfo, me, userData } =
		useContext(EdenContext);

	const agGridRef = useRef(null);
	const { query } = useQuery();
	const account = query.get('account');

	const [drawer, setDrawer] = useState(false);
	const [gridApi, setGridApi] = useState(null);
	const [filterAccounts, setFilterAccounts] = useState([]);
	const [currentSort, setCurrentSort] = useState({
		colId: 'Account',
		sort: 'asc',
	});
	const [popConfirm, setPopConfirm] = useState(false);
	const [allocatingAcc, setAllocatingAcc] = useState(false);
	const [allocatingError, setAllocatingError] = useState(false);
	const [accAllocated, setAccAllocated] = useState([]);
	const [showAccAllocatedBanner, setShowAccAllocatedBanner] = useState(false);

	const onSortChanged = (event) => {
		const sortState = event.columnApi.getColumnState();
		setCurrentSort(
			sortState.find((col) => col.sort != null) || {
				colId: 'Account',
				sort: 'asc',
			}
		);
	};

	useEffect(() => {
		if (customerOutreach) {
			gridApi?.removeRowGroupColumns([
				'Account',
				'SalesRep',
				'PrimaryCompanyType',
				'is_future_contact',
				'is_senior',
			]);
		}
		getData(); //eslint-disable-next-line
	}, [userType, customerOutreach]);

	const [summaryColumns] = useState([
		{
			headerName: 'Release to House?',
			field: 'realease',
			checkboxSelection: true,
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 120,
		},
		{ headerName: 'Account', field: 'Account', enableRowGroup: true },
		{ headerName: 'Sales Rep', field: 'SalesRep' },
		{
			headerName: 'Country',
			field: 'AcctCountry',
			cellRenderer: memo(CountryCellRenderer),
			filter: 'agSetColumnFilter',
			filterParams: {
				cellRenderer: memo(CountryCellRenderer),
				cellRendererParams: { isFilterRenderer: true },
				applyMiniFilterWhileTyping: true,
			},
		},
		{
			headerName: 'Expiry Date',
			field: 'expiry_date',
			minWidth: 170,
			filter: 'agDateColumnFilter',
			filterParams: dateFilterParams,
			cellRenderer: (p) => p.value && moment(p.value).format('MMM-DD-YYYY'),
		},
		{
			headerName: 'FY Billed',
			field: 'fy_billed',
			cellClass: 'numberType',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
		},
		{
			headerName: 'Total Backorder',
			field: 'total_backorder',
			cellClass: 'numberType',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
		},
		{
			headerName: 'Last In (Days)',
			field: 'last_incoming_date_label',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 80,
			filter: 'agSetColumnFilter',
			cellRenderer: (params) => {
				return labelFormatter(params.value);
			},
			filterParams: {
				comparator: customComparator,
				valueFormatter: (params) => labelFormatter(params.value),
			},
		},
		{
			headerName: 'Last In (To)',
			field: 'last_incoming_to',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 120,
		},
		{
			headerName: 'Last Out (Days)',
			field: 'last_outgoing_date_label',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 100,
			filter: 'agSetColumnFilter',
			cellRenderer: (params) => {
				return labelFormatter(params.value);
			},
			filterParams: {
				comparator: customComparator,
				valueFormatter: (params) => labelFormatter(params.value),
			},
		},
		{ headerName: 'To Whom', field: 'last_outgoing_name' },
		{
			headerName: 'Last Eden Outrch (Days)',
			field: 'last_outgoing_outreach_date_label',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 100,
			filter: 'agSetColumnFilter',
			cellRenderer: (params) => {
				return labelFormatter(params.value);
			},
			filterParams: {
				comparator: customComparator,
				valueFormatter: (params) => labelFormatter(params.value),
			},
		},
		{
			headerName: 'Last Automated Outrch (Days)',
			field: 'last_automated_outreach_date_label',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 100,
			filter: 'agSetColumnFilter',
			cellRenderer: (params) => {
				return labelFormatter(params.value);
			},
			filterParams: {
				comparator: customComparator,
				valueFormatter: (params) => labelFormatter(params.value),
			},
		},
	]);

	const [outreachColumns] = useState([
		{
			headerName: 'Actions',
			field: 'outreach',
			checkboxSelection: true,
			showDisabledCheckboxes: true,
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			cellRenderer: memo(Actions),
			cellRendererParams: {
				columnName: 'actions',
			},
			headerComponent: (p) => (
				<ToolTip
					placement="bottomLeft"
					title="All contacts across all accounts and web pages are selected based on what’s in the grid"
				>
					{p.displayName}
				</ToolTip>
			),
		},
		{
			headerName: 'Account',
			field: 'Account',
			enableRowGroup: true,
			sort: 'asc',
			minWidth: 160,
			filter: 'agSetColumnFilter',
			filterParams: {
				applyMiniFilterWhileTyping: true,
			},
		},
		{
			headerName: 'Sales Rep',
			field: 'SalesRep',
			cellRenderer: (p) => titleCase(p.value),
			enableRowGroup: true,
			hide: userType === 'sr',
		},
		{ headerName: 'Type', field: 'PrimaryCompanyType', enableRowGroup: true },
		{
			headerName: 'First',
			field: 'ContactFirstName',
			cellClassRules: {
				'cell-error-bg': (p) => p.value?.length <= 2,
			},
			cellRenderer: memo(Actions),
			cellRendererParams: {
				columnName: 'firstName',
			},
		},
		{
			headerName: 'Last',
			field: 'ContactLastName',
			cellClassRules: {
				'cell-error-bg': (p) => p.value?.length <= 2,
			},
			cellRenderer: memo(Actions),
			cellRendererParams: {
				columnName: 'lastName',
			},
		},
		{
			headerName: 'Email',
			field: 'ContactEmail',
			cellRenderer: memo(Actions),
			cellRendererParams: {
				columnName: 'email',
			},
		},
		{
			headerName: 'Phone',
			field: 'ContactPhone',
		},
		{
			headerName: 'Title',
			field: 'JobTitle',
			cellRenderer: memo(Actions),
			cellRendererParams: {
				columnName: 'title',
			},
		},
		{
			headerName: 'Contact?',
			field: 'is_future_contact',
			cellStyle: { padding: '0 0 0 25px' },
			enableRowGroup: true,
			filter: 'agSetColumnFilter',
			cellRenderer: 'agCheckboxCellRenderer',
			cellEditor: 'agCheckboxCellEditor',
			editable: true,
		},
		{
			headerName: 'Sr.?',
			field: 'is_senior',
			editable: true,
			enableRowGroup: true,
			cellEditor: 'agRichSelectCellEditor',
			minWidth: 70,
			cellStyle: { backgroundColor: '#EEF7FE' },
			cellEditorParams: {
				searchType: 'match',
				allowTyping: true,
				filterList: true,
				highlightMatch: true,
				valueListMaxHeight: 220,
				valueListMaxWidth: 50,
				values: ['', 'Yes'],
			},
		},
		{
			headerName: 'Last In (Days)',
			field: 'last_incoming_date_label',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 80,
			filter: 'agSetColumnFilter',
			cellRenderer: (params) => {
				return labelFormatter(params.value);
			},
			filterParams: {
				comparator: customComparator,
				valueFormatter: (params) => labelFormatter(params.value),
			},
		},
		{
			headerName: 'Last In Email',
			field: 'last_incoming_email_id',
			cellStyle: { padding: '4px 0 0 25px' },
			cellClass: 'hyperlinks',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 80,
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			cellRenderer: memo(MailPreview),
			cellRendererParams: {
				mailPreview: 'V2',
			},
		},
		{
			headerName: 'Last In (To)',
			field: 'last_incoming_to',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 120,
		},
		{
			headerName: 'Last Out (Days)',
			field: 'last_outgoing_date_label',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 100,
			filter: 'agSetColumnFilter',
			cellRenderer: (params) => {
				return labelFormatter(params.value);
			},
			filterParams: {
				comparator: customComparator,
				valueFormatter: (params) => labelFormatter(params.value),
			},
		},
		{
			headerName: 'Last Out Email',
			field: 'last_outgoing_email_id',
			cellClass: 'hyperlinks',
			cellStyle: { padding: '4px 0 0 25px' },
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 80,
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			cellRenderer: memo(MailPreview),
			cellRendererParams: {
				mailPreview: 'V2',
			},
		},
		{
			headerName: 'Last Outrch (Days)',
			field: 'last_outgoing_outreach_date_label',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 100,
			filter: 'agSetColumnFilter',
			cellRenderer: (params) => {
				return labelFormatter(params.value);
			},
			filterParams: {
				comparator: customComparator,
				valueFormatter: (params) => labelFormatter(params.value),
			},
		},
		{
			headerName: 'Last Automated Outrch (Days)',
			field: 'last_automated_outreach_date_label',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 100,
			filter: 'agSetColumnFilter',
			cellRenderer: (params) => {
				return labelFormatter(params.value);
			},
			filterParams: {
				comparator: customComparator,
				valueFormatter: (params) => labelFormatter(params.value),
			},
		},
		{
			headerName: 'Last Outrch',
			field: 'last_outgoing_outreach_email_id',
			cellClass: 'hyperlinks',
			cellStyle: { padding: '4px 0 0 25px' },
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 90,
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			cellRenderer: memo(MailPreview),
		},
	]);

	const getRowId = useMemo(() => {
		return (params) => {
			return params.data.ContactId;
		};
	}, []);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	const isRowSelectable = useCallback((params) => {
		return !!params.data && params.data.is_future_contact === true;
	}, []);

	const autoGroupColumnDef = useMemo(() => {
		return {
			minWidth: 200,
			filter: 'agGroupColumnFilter',
		};
	}, []);

	/**
	 * Gets data of selected rows
	 */
	const onSelectionChanged = (e) => {
		setSelectedRows(e.api.getSelectedRows());
		if (agGridRef.current && customerOutreach) {
			agGridRef.current.api?.getColumnState()?.forEach((col) => {
				if (col.colId === currentSort.colId && col.sort !== currentSort.sort) {
					agGridRef.current.api?.applyColumnState({
						state: [currentSort],
						applyOrder: true,
					});
				}
			});
		}
	};

	/**
	 * On cell value change for isSenior column
	 */
	const onCellValueChanged = useCallback((e) => {
		const cellValue = e.data;

		axios
			.post(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=customer_outreach_checkbox_operation&contact_master_id=${cellValue.ContactId}&is_senior=${cellValue.is_senior}&is_future_contact=${cellValue.is_future_contact}`
			)
			.then((response) => {
				const column = e.column.colDef.field;
				setTimeout(() => {
					e.api.refreshCells({
						force: true,
						columns: [column],
						rowNodes: [e.node],
					});
					e?.api?.tabToNextCell();
				}, 500);
			});
	}, []);

	const onGridReady = (e) => {
		setGridApi(e.api);
	};

	const exportToExcel = () => {
		const customerOutreachExcelParams = {
			sheetName: customerOutreach ? 'Customer Outreach' : 'Customer Summary',
			fileName: customerOutreach
				? 'Customer Outreach.xlsx'
				: 'Customer Summary.xlsx',
			columnKeys: customerOutreach
				? [
						'Account',
						'SalesRep',
						'PrimaryCompanyType',
						'ContactFirstName',
						'ContactLastName',
						'ContactEmail',
						'JobTitle',
						'is_future_contact',
						'is_senior',
						'last_incoming_date_label',
						'last_incoming_email_id',
						'last_incoming_to',
						'last_outgoing_date_label',
						'last_outgoing_email_id',
						'last_outgoing_outreach_date_label',
						'last_outgoing_outreach_email_id',
				  ]
				: [
						'Account',
						'AcctType',
						'AcctCountry',
						'expiry_date',
						'fy_billed',
						'total_backorder',
						'last_incoming_date_label',
						'last_incoming_to',
						'last_outgoing_date_label',
						'last_outgoing_name',
						'last_outgoing_outreach_date_label',
				  ],
			processCellCallback: (p) => {
				const colId = p.column.colId;
				if (colId === 'is_future_contact') {
					return p.value === false ? 'No' : p.value === true ? 'Yes' : '';
				}
				if (
					colId === 'last_incoming_date_label' ||
					colId === 'last_outgoing_date_label' ||
					colId === 'last_outgoing_outreach_date_label'
				) {
					return labelFormatter(p.value);
				}
				if (
					colId === 'last_incoming_email_id' ||
					colId === 'last_outgoing_email_id' ||
					colId === 'last_outgoing_outreach_email_id'
				) {
					return (
						p.value &&
						`${messageURl?.url}${p?.value?.replace(/=+$/, '')}%3D.eml`
					);
				}
				if (p.column.colId === 'expiry_date') {
					if (p?.value) {
						return moment(p.value).format('MMM-DD-YYYY');
					}
					return '';
				}
				return p.value;
			},
		};
		gridApi.exportDataAsExcel(customerOutreachExcelParams);
	};

	const processCellForClipboard = (p) => {
		if (p.column.colId === 'is_future_contact') {
			return p.value === false ? 'No' : p.value === true ? 'Yes' : '';
		}

		return p.value;
	};

	// export excel sheet style
	const excelStyles = useMemo(() => {
		return [
			{
				id: 'header',
				font: {
					bold: true,
				},
			},
			{
				id: 'hyperlinks',
				font: {
					underline: 'Single',
					color: '#358ccb',
				},
			},
			{
				id: 'numberType',
				numberFormat: {
					format: '$ #,##0',
				},
				alignment: {
					horizontal: 'Distributed',
				},
			},
		];
	}, []);

	const defaultExcelExportParams = useMemo(() => {
		return {
			autoConvertFormulas: true,
			processCellCallback: (params) => {
				const field = params.column.getColDef().field;

				return field === 'last_incoming_email_id' ||
					field === 'last_outgoing_email_id' ||
					field === 'last_outgoing_outreach_email_id'
					? `=HYPERLINK("${messageURl?.url}${params?.value?.replace(
							/=+$/,
							''
					  )}%3D.eml")`
					: params.value;
			},
		}; //eslint-disable-next-line
	}, []);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
				setFilterAccounts([]);
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	useEffect(() => {
		if (filterAccounts.length > 0 && gridApi) {
			const filterInstance = gridApi.getFilterInstance('Account');

			if (filterInstance) {
				filterInstance.setModel({
					values: filterAccounts,
				});
				gridApi.onFilterChanged();
			}
		}
		if (filterAccounts.length === 0 && gridApi) {
			gridApi.setFilterModel(null);
			gridApi?.setQuickFilter('');
		}
	}, [filterAccounts, gridApi]);

	/**
	 * Allocate account to house
	 *
	 */

	const allocateAccount = () => {
		setPopConfirm(false);
		setAllocatingAcc(true);
		setShowAccAllocatedBanner(false);
		setAccAllocated([]);
		const promises = selectedRows.map((el, index, arr) =>
			axios
				.post(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=release_account_to_house&email=${userMail}&account_id=${el?.AcctId}&master_id=${userInfo?.ViewAsLoggedinUser?.MasterId}`
				)
				.then((response) => {
					setAccAllocated(arr.length);
				})
				.catch((err) => {
					console.log(err);
					setAllocatingError(true);
				})
		);
		Promise.all(promises)
			.then(() => {
				axios.post(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=send_release_notification&userEmail=${userMail}&userName=${
						userData?.name || me?.givenName || me?.displayName
					}&data=${JSON.stringify(selectedRows)}`
				);
			})
			.then(() => {
				setAllocatingAcc(false);
				setShowAccAllocatedBanner(true);
				setSelectedRows([]);
				getData();
			})
			.catch((error) => {
				console.error('Error during promise execution:', error);
			});
	};

	const context = useMemo(() => {
		return {
			COUNTRY_CODES: COUNTRY_CODES,
		};
	}, []);

	useEffect(() => {
		if (customerOutreach && account && gridApi) {
			const getCustomerColumn = gridApi?.getFilterInstance('Account');

			getCustomerColumn?.setModel({ values: [account] });
			gridApi?.onFilterChanged();
		}

		//eslint-disable-next-line
	}, [gridApi]);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="Customer Outreach"
				icon={<TbSpeakerphone style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="Customer Outreach"
				info={
					<>
						<span>
							This section enables you to get a summary of all of your
							account(s) and more importantly, reach out to your accounts by
							asking or telling them anything that is of your interest.
							Typically, you can ask or tell them information about one of the
							following items:
						</span>
						<br />
						<ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
							<li>Do they have any RFQs?</li>
							<li>Share any SCI company or product news.</li>
						</ul>
						<br />
						<span>
							Start by reviewing your accounts summary and then pick the
							accounts you’d like to follow-up with.
						</span>
					</>
				}
			/>

			<InnerHeader
				disableExcelExport={!isNotEmptyArray(data) || error || isLoading}
				exportToExcel={customerOutreach && exportToExcel}
				updatePage
				updateText="Refresh"
				onUpdate={getData}
				massMail={customerOutreach}
				sendMassmail={() => setDrawer(true)}
				disableMassMail={isLoading || selectedRows.length <= 0}
			/>
			<div className={styles['customer-outreach']}>
				{contactInfoUpdate?.response?.code === 500 && (
					<NotificationBar
						redBar
						message="The system is unable to update the Contact Info to the CRM. You will be notified once the problem is fixed. Sorry for the inconvenience."
					/>
				)}
				{contactInfoUpdate?.response?.code === 200 && (
					<NotificationBar greenBar message={contactInfoUpdate?.message} />
				)}

				{sendMailCount > 0 && (
					<NotificationBar
						greenBar
						message={`Congrats! ${sendMailCount} contacts has been successfully reached out. Good luck generating more business with them.`}
						onClose={() => setSendMailCount(0)}
					/>
				)}

				{showAccAllocatedBanner && !allocatingError && (
					<NotificationBar
						greenBar
						messageStyle={{ fontSize: '14px', fontWeight: 400 }}
						message={
							<>
								Congratulations! <b>{accAllocated}</b> accounts have been
								release to the House Accounts List.
							</>
						}
					/>
				)}

				{allocatingError && (
					<NotificationBar
						messageStyle={{ fontSize: '14px', fontWeight: 400 }}
						redBar
						message={
							<>
								There seems to be an error tagging your accounts in Focus. IT
								has been notified and HelpDesk has been notified to manually tag
								your accounts. Contact Help Desk today to get a status update.
							</>
						}
					/>
				)}

				<div className={styles['tabs-wrapper']}>
					<div
						className={`${styles['tabs']} ${
							customerSummary ? styles['tab-active'] : ''
						}`}
						onClick={showCustomerSummary}
					>
						<p className={styles['tab-text']}>Customer Summary</p>
					</div>

					<div
						className={`${styles['tabs']} ${
							customerOutreach ? styles['tab-active'] : ''
						}`}
						onClick={showCustomerOutreach}
					>
						<p className={styles['tab-text']}>Customer Outreach</p>
					</div>
				</div>

				<>
					<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
						{customerSummary ? (
							<Popconfirm
								placement="bottomLeft"
								okButtonColor="red"
								open={popConfirm}
								title="Release Accounts"
								description="Are you sure you want to release this accounts to House List?"
								okText="Yes"
								cancelText="Cancel"
								onConfirm={allocateAccount}
								onCancel={() => setPopConfirm(false)}
								icon={
									<QuestionCircleOutlined
										style={{
											color: 'red',
										}}
									/>
								}
							>
								<Button
									color={allocatingAcc ? 'dark' : 'red'}
									onClick={() => setPopConfirm(true)}
									loading={allocatingAcc}
									disabled={
										isLoading || allocatingAcc || selectedRows?.length <= 0
									}
								>
									{allocatingAcc
										? 'Releasing to House Accounts... Please Wait'
										: 'Release to the House Accounts List​'}
								</Button>
							</Popconfirm>
						) : (
							<Select
								disabled={isLoading}
								title=" Select Accounts"
								style={{ minWidth: '200px' }}
								multiple
								options={accounts?.map((el) => ({
									value: el,
									label: el,
								}))}
								popupMatchSelectWidth
								maxTagCount={4}
								placeholder="Please Select Account"
								onChange={(value) => setFilterAccounts(value)}
								value={filterAccounts}
							/>
						)}
					</div>

					{isLoading && (
						<div className={styles['loading-wrapper']}>
							<LoadingMessage message="Loading..." />
						</div>
					)}

					{error && !isLoading && (
						<div className={styles['error-wrapper']}>
							<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
						</div>
					)}

					{!isNotEmptyArray(data) && !error && !isLoading && (
						<div className={styles['no-data-wrapper']}>
							<NoData message="Its all empty here." />
						</div>
					)}
					{!isLoading && !error && isNotEmptyArray(data) && (
						<>
							<div className={styles['table-wrapper']}>
								<div className={`ag-theme-custom ${styles['table']} `}>
									{customerOutreach && (
										<AgGridReact
											ref={agGridRef}
											rowData={data}
											columnDefs={outreachColumns}
											defaultColDef={defaultColDef}
											animateRows={true}
											pagination={true}
											paginationPageSizeSelector={false}
											enableRangeSelection={true}
											suppressRowClickSelection={true}
											rowSelection={'multiple'}
											onSelectionChanged={onSelectionChanged}
											onCellValueChanged={onCellValueChanged}
											onGridReady={onGridReady}
											rowGroupPanelShow="always"
											groupDisplayType={'multipleColumns'}
											autoGroupColumnDef={autoGroupColumnDef}
											groupMaintainOrder={true}
											groupSelectsChildren={true}
											excelStyles={excelStyles}
											defaultExcelExportParams={defaultExcelExportParams}
											processCellForClipboard={processCellForClipboard}
											isRowSelectable={isRowSelectable}
											getRowId={getRowId}
											onSortChanged={onSortChanged}
										/>
									)}
									{customerSummary && (
										<AgGridReact
											ref={agGridRef}
											rowData={data}
											columnDefs={summaryColumns}
											defaultColDef={defaultColDef}
											animateRows={true}
											pagination={true}
											paginationPageSizeSelector={false}
											enableRangeSelection={true}
											suppressRowClickSelection={true}
											rowSelection={'single'}
											onSelectionChanged={onSelectionChanged}
											onGridReady={onGridReady}
											excelStyles={excelStyles}
											suppressContextMenu={userType !== 'admin'}
											defaultExcelExportParams={defaultExcelExportParams}
											processCellForClipboard={processCellForClipboard}
											context={context}
										/>
									)}
								</div>
							</div>
						</>
					)}
				</>
			</div>

			{/* Mail Config */}
			{drawer && (
				<MailConfig
					drawer={drawer}
					setDrawer={setDrawer}
					selectedRows={selectedRows}
					setSelectedRows={setSelectedRows}
					gridApi={gridApi}
				/>
			)}
		</div>
	);
};

export default CustomerOutreach;
