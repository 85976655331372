import { createContext } from 'react';

const MyPastEmailsContext = createContext();

export const MyPastEmailsProvider = ({ children }) => {
	return (
		<MyPastEmailsContext.Provider value={{}}>
			{children}
		</MyPastEmailsContext.Provider>
	);
};

export default MyPastEmailsContext;
