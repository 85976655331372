import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { AiOutlineAliwangwang } from 'react-icons/ai';

import AccessDenied from '../../../components/accessDenied';
import EdenContext from '../../../context/edenContext';
import Actions from './Actions';
import BreadCrumb from '../../../components/breadcrumb';
import SectionInfo from '../../../components/sectionInfo';
import InnerHeader from '../../../components/innerHeader';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';
import Button from '../../../components/button';
import LeadsExclusionContext from '../../../context/adminLeadsExclusionContext';

import styles from './leadsExclusion.module.scss';

const LeadsExclusion = () => {
	const { userType } = useContext(EdenContext);
	const { getData, data, setData, isLoading, error, onCellValueChanged } =
		useContext(LeadsExclusionContext);

	const [gridApi, setGridApi] = useState();

	useEffect(() => {
		getData(); //eslint-disable-next-line
	}, []);

	const onGridReady = (p) => {
		setGridApi(p.api);
	};

	const [columnDefs] = useState([
		{ field: 'currentTime', hide: true, sort: 'desc' },
		{ field: 'id', sort: 'desc', hide: true },
		{
			headerName: 'S No.',
			field: 'sno',
			cellRenderer: (p) => p.rowIndex + 1,
			maxWidth: 55,
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			editable: false,
		},
		{
			field: 'type',
			headerName: 'Type',
			cellRenderer: (p) => (p.value ? p.value : ''),
			editable: false,
		},
		{
			field: 'parameter',
			headerName: 'Parameter',
			cellRenderer: (p) => (p.value ? p.value : ''),
		},
		{
			field: 'updatedAt',
			headerName: 'Date/Time',
			sort: 'desc',
			editable: false,
			cellRenderer: (p) =>
				p.value
					? moment
							.utc(p.value)
							.tz('Asia/Singapore')
							.format('MMM-DD-YYYY hh:mm A')
					: '',
		},
		{
			field: 'actions',
			headerName: 'Actions',
			maxWidth: 90,
			cellStyle: { padding: '4px 0 0 10px' },
			cellRenderer: memo(Actions),
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			editable: false,
		},
	]);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			flex: 2,
			floatingFilter: true,
			editable: true,
			minWidth: 100,
			filter: 'agMultiColumnFilter',
			suppressMovable: true,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
		}),
		[]
	);

	const addRow = () => {
		const newRow = { currentTime: Date.now(), type: 'Domain' };
		setData([newRow, ...data]);
	};

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.altKey && (event.key === 'A' || event.key === 'a')) {
				gridApi.autoSizeAllColumns();
			}
			if (event.altKey && (event.key === 'R' || event.key === 'r')) {
				gridApi.resetColumnState();
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	return (
		<div className={styles['wrapper']}>
			{userType === 'admin' ? (
				<>
					<BreadCrumb
						route1="Leads Exclusion"
						icon={<AiOutlineAliwangwang style={{ fontSize: '20px' }} />}
					/>
					<SectionInfo
						removeshortcutkeys
						title="Leads Exclusion"
						info="This section enables the administrator to manage which emails and domains to exclude while searching for Leads."
					/>
					<InnerHeader
						updateText="Refresh Data"
						updatePage
						onUpdate={() => getData()}
					/>

					<div className={styles['lead-time-exclusion']}>
						<Button disabled={error || isLoading} onClick={addRow}>
							Add Leads Exclusion
						</Button>

						{isLoading && (
							<div className={styles['loading-wrapper']}>
								<LoadingMessage message="Loading..." />
							</div>
						)}
						{error && !isLoading && (
							<div className={styles['error-wrapper']}>
								<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
							</div>
						)}

						{!isNotEmptyArray(data) && !error && !isLoading && (
							<div className={styles['no-data-wrapper']}>
								<NoData message="Its all empty here." />
							</div>
						)}

						{!isLoading && !error && isNotEmptyArray(data) && (
							<div className={styles['table-wrapper']}>
								<div className={`ag-theme-custom ${styles['table']} `}>
									<AgGridReact
										onGridReady={onGridReady}
										rowData={data}
										columnDefs={columnDefs}
										defaultColDef={defaultColDef}
										animateRows={true}
										pagination={true}
										paginationPageSizeSelector={false}
										onCellValueChanged={onCellValueChanged}
										suppressLastEmptyLineOnPaste={true}
										enableCellChangeFlash={true}
										enableRangeSelection={true}
									/>
								</div>
							</div>
						)}
					</div>
				</>
			) : (
				<AccessDenied message="You are not an Admin" />
			)}
		</div>
	);
};

export default LeadsExclusion;
