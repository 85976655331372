import { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PiMicrosoftOutlookLogoFill } from 'react-icons/pi';
import { AgGridReact } from 'ag-grid-react';
import { Checkbox } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { HiOutlineSearch } from 'react-icons/hi';

import BreadCrumb from '../../components/breadcrumb';
import SectionInfo from '../../components/sectionInfo';
import InnerHeader from '../../components/innerHeader';
import Select from '../../components/select';
import Input from '../../components/input';
import Button from '../../components/button';
import LoadingMessage from '../../components/loaderMessage';
import ErrorMessage from '../../components/errorMessage';
import { isNotEmptyArray } from '../../utils';
import NoData from '../../components/nodata';
import MailPreview from '../../components/MailPreview';
import EdenContext from '../../context/edenContext';

import styles from './myPastEmails.module.scss';

const MyPastEmails = () => {
	const { userMail } = useContext(EdenContext);
	const gridRef = useRef();
	const [gridApi, setGridApi] = useState(null);
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [dpValue, setDpValue] = useState('incoming');
	const [searchValue, setSearchValue] = useState('');
	const [checkbox, setCheckbox] = useState(['searchSubject']);

	const [columnsDefs] = useState([
		{
			headerName: 'Mail',
			field: 'cid',
			cellStyle: { padding: '4px 0 0 25px' },
			cellClass: 'hyperlinks',
			wrapHeaderText: true,
			autoHeaderHeight: true,
			minWidth: 80,
			floatingFilter: false,
			suppressHeaderMenuButton: true,
			floatingFilterComponentParams: {
				suppressFilterButton: true,
			},
			cellRenderer: memo(MailPreview),
			cellRendererParams: {
				mailPreview: 'V2',
			},
		},
		{
			field: 'receivedDateTime',
			headerName: 'Date/TimeDate/Time​',
			cellRenderer: (p) => (
				<> {p.value ? moment(p.value).format('MMM-DD-YYYY hh:mm A') : '--'}</>
			),
		},
		{
			field: 'senderAddress',
			headerName: 'From​',
		},
		{
			field: 'toRecipients',
			headerName: 'To',
		},
		{
			headerName: 'Subject',
			field: 'subject',
		},
		{
			headerName: 'Attachment? Y/N',
			field: 'hasAttachments',
			cellRenderer: (p) => <> {p.value === 0 ? 'No' : 'Yes'}</>,
		},
	]);

	const onChange = (value) => {
		setDpValue(value);
	};

	const handleSearch = () => {
		setIsLoading(true);
		setData([]);
		setError(null);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=mailvault_search`,
				{
					params: {
						keyword: searchValue,
						searchSubject: checkbox.includes('searchSubject'),
						searchBody: checkbox.includes('searchBody'),
						searchSenderEmail: checkbox.includes('searchSenderEmail'),
						direction: dpValue,
						limit: 0,
						offset: 0,
						user: userMail,
						token: localStorage.getItem('accessToken'),
					},
				}
			)
			.then((response) => {
				setData(response.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const onGridReady = (params) => {
		setGridApi(params.api);
	};

	//Export to excel
	const exportToExcel = () => {
		gridApi.exportDataAsExcel();
	};

	// export excel sheet style
	const excelStyles = [
		{
			id: 'header',
			font: {
				bold: true,
			},
		},
		{
			id: 'hyperlinks',
			font: {
				underline: 'Single',
				color: '#358ccb',
			},
		},
	];

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			floatingFilter: true,
			suppressMovable: true,
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	const checkOptions = [
		{
			label: 'Subject',
			value: 'searchSubject',
		},
		{
			label: 'Body',
			value: 'searchBody',
		},
		{
			label: 'Sender Email',
			value: 'searchSenderEmail',
		},
	];

	const onCheckboxChange = (value, d) => {
		setCheckbox(value);
	};

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="My Past Emails"
				icon={<PiMicrosoftOutlookLogoFill style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="My Past Emails"
				info={
					<div>
						This section allows you to look for historical emails that are
						probably not saved in your mailbox. <br />
						<br />
						Please take note of the following important information:
						<br />
						<ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
							<li>
								Our system has only saved information since January 1st 2023.
							</li>
							<li>
								If you are looking for information prior to that, you may need
								to look at your own Archival emails.
							</li>
							<li>
								The search box below allows you to search for keywords by either
								Subject Line or Body text.
							</li>
							<li>
								Because of the volume of emails, it might take some time for the
								results to appear. Please be patient!
							</li>
							<li>
								If the system determines that the results will take more than 30
								seconds, an email shall be sent to you.
							</li>
						</ul>
					</div>
				}
			/>
			<InnerHeader exportToExcel={exportToExcel} />
			<div className={styles['my-past-emails']}>
				<Checkbox.Group
					options={checkOptions}
					defaultValue={checkbox}
					onChange={onCheckboxChange}
				/>
				<div className={styles['input-checkbox']}>
					<div className={styles['dropdown-input']}>
						<Select
							placeholder="Drill down by"
							style={{
								width: 100,
							}}
							options={[
								{ value: 'incoming', label: 'Incoming' },
								{ value: 'outgoing', label: 'Outgoing' },
							]}
							defaultValue={dpValue}
							onChange={onChange}
							disabled={isLoading}
						/>
						<Input
							style={{ width: '450px' }}
							placeholder={
								'Search keywords in to/from emails, subject lines or body text…'
							}
							onChange={(e) => setSearchValue(e.target.value)}
							onPressEnter={handleSearch}
							disabled={isLoading}
							id="searchValue"
							maxLength={30}
							value={searchValue}
						/>
					</div>

					<Button
						onClick={handleSearch}
						disabled={
							searchValue?.length <= 1 || isLoading || checkbox.length <= 0
						}
					>
						Search <HiOutlineSearch />
					</Button>
				</div>

				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}
				{!isNotEmptyArray(data) && !isLoading && !error && (
					<div className={styles['no-data-wrapper']}>
						<NoData
							search
							message="Find past emails via the search box on top!"
						/>
					</div>
				)}

				{!isLoading && !error && isNotEmptyArray(data) && (
					<div className={`ag-theme-custom ${styles['table-wrapper']} `}>
						<AgGridReact
							ref={gridRef}
							onGridReady={onGridReady}
							rowData={data}
							columnDefs={columnsDefs}
							defaultColDef={defaultColDef}
							// rowModelType="serverSide"
							animateRows={true}
							floatingFilter={true}
							excelStyles={excelStyles}
							pagination={true}
							paginationPageSizeSelector={false}
							// paginationPageSize={50}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default MyPastEmails;
