import {
	useMemo,
	useState,
	useContext,
	useRef,
	useCallback,
	useEffect,
	memo,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { LineChartOutlined } from '@ant-design/icons';
import { MdContentCopy } from 'react-icons/md';
import { message } from 'antd';
import axios from 'axios';

import AccountSummaryCard from '../../../components/accountSummaryCard';
import InnerHeader from '../../../components/innerHeader';
import NotificationBar from '../../../components/notificationBar';
import BreadCrumb from '../../../components/breadcrumb';
import TableDataCountInput from '../../../components/tableDataCountInput';
import Cascader from '../../../components/cascader';
import useFetch from '../../../hooks';
import EdenContext from '../../../context/edenContext';
import { COUNTRY_CODES } from '../../../columns';
import ToolTip from '../../../components/tooltip';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import NoData from '../../../components/nodata';
import TabelToolTipNotification from '../../../components/tableTooltipNotification';
import NoDataImage from '../../../images/nodata.svg';
import { getFiscalYear, titleCase } from '../../../utils';
import { CountryCellRenderer } from '../../../columns/countryCellRenderer';
import {
	decimalFilterParams,
	dollarDecimalFilterParams,
	numberFilterParams,
} from '../../../filters';

import styles from './customerReport.module.scss';

const CustomerReport = () => {
	const navigate = useNavigate();
	const gridRef = useRef();
	const {
		userType,
		me,
		userInfo,
		cascaderDesignation,
		setCascaderDesignation,
		cascaderOption,
		setCascaderOption,
		srData,
	} = useContext(EdenContext);

	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [gridApi, setGridApi] = useState();

	const [columnsDefs] = useState([
		{
			field: 'SalesRep',
			headerName: 'Assigned SR',
			minWidth: 150,
			cellRenderer: (p) => titleCase(p.value),
		},
		{
			field: 'SiSalesRep',
			headerName: ' Invoiced SR',
			minWidth: 150,
			cellRenderer: (p) => titleCase(p.value),
		},
		{ field: 'ParentAccount', headerName: 'Parent Account', minWidth: 120 },
		{ field: 'Customer', headerName: 'Customer', minWidth: 120 },
		{
			field: 'no_of_rfqs',
			headerName: 'No. of RFQs',
			minWidth: 120,
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
			cellRenderer: (p) => {
				const email =
					p.data.SalesRep &&
					srData?.filter((el) => el.salesRepName === p.data.SalesRep)?.[0]
						?.salesRepEmail;

				return p.value > 0 ? (
					<ToolTip title={`Click here to see all ${p.value} RFQs received`}>
						<Link
							style={{
								color: '#0086D2',
								fontWeight: 'bold',
								textDecoration: 'none',
							}}
							to={`/customer-rfqs?customer=${p.data.Customer}&salesrep=${email}&view=rfq-view`}
							target="_blank"
						>
							{p.value}
						</Link>
					</ToolTip>
				) : (
					p.value
				);
			},
		},
		{
			field: 'no_of_lines',
			headerName: 'No. of Lines',
			minWidth: 120,
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
			cellRenderer: (p) => {
				const email =
					p.data.SalesRep &&
					srData?.filter((el) => el.salesRepName === p.data.SalesRep)?.[0]
						?.salesRepEmail;

				return p.value > 0 ? (
					<ToolTip
						title={`Click here to see all ${p.value} No. of Lines received`}
					>
						<Link
							style={{
								color: '#0086D2',
								fontWeight: 'bold',
								textDecoration: 'none',
							}}
							to={`/customer-rfqs?customer=${p.data.Customer}&salesrep=${email}&view=mpn-view`}
							target="_blank"
						>
							{p.value}
						</Link>
					</ToolTip>
				) : (
					p.value
				);
			},
		},
		{
			field: 'no_of_lines_billed',
			headerName: 'No. of Lines Billed',
			minWidth: 120,
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
			cellRenderer: (p) =>
				p.value > 0 ? (
					<ToolTip
						title={`Click here to see all ${p.value} No. of Lines Billed`}
					>
						<Link
							style={{
								color: '#0086D2',
								fontWeight: 'bold',
								textDecoration: 'none',
							}}
							to={`/mpn-report?customer=${p.data.Customer?.replace(
								' <Released>',
								''
							)}&salesrep=${p.data.SiSalesRep}`}
							target="_blank"
						>
							{p.value}
						</Link>
					</ToolTip>
				) : (
					p.value
				),
		},
		{
			field: 'no_of_per_lines_converted',
			headerName: '% lines Converted',
			filter: 'agSetColumnFilter',
			filterParams: numberFilterParams,
			cellRenderer: (p) => p.value > 0 && `${p.value}%`,
		},
		{ field: 'CompanyType', headerName: 'Type' },
		{
			field: 'Country',
			headerName: 'Country',
			cellRenderer: memo(CountryCellRenderer),
			filter: 'agSetColumnFilter',
			filterParams: {
				cellRenderer: memo(CountryCellRenderer),
				cellRendererParams: { isFilterRenderer: true },
				applyMiniFilterWhileTyping: true,
			},
			minWidth: 150,
		},
		{
			field: 'FyPrevious',
			headerName: getFiscalYear(4) - 1,
			cellClass: 'numberType',
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
			minWidth: 150,
			aggFunc: 'sum',
			sort: 'desc',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
		},
		{
			field: 'FyCurrent',
			cellClass: 'numberType',
			headerName: getFiscalYear(4),
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
			minWidth: 150,
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
		},
		{
			field: 'FyQ1',
			cellClass: 'numberType',
			headerName: 'Q1',
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
			minWidth: 110,
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
		},
		{
			field: 'FyQ2',
			cellClass: 'numberType',
			headerName: 'Q2',
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
			minWidth: 110,
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
		},
		{
			field: 'FyQ3',
			cellClass: 'numberType',
			headerName: 'Q3',
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
			minWidth: 110,
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
		},
		{
			field: 'FyQ4',
			cellClass: 'numberType',
			headerName: 'Q4',
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
			minWidth: 110,
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
		},

		{
			field: 'Backorder',
			cellClass: 'numberType',
			headerName: 'Backorder',
			cellRenderer: (p) => `$ ${p.value?.toLocaleString('en-US')}`,
			minWidth: 150,
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: dollarDecimalFilterParams,
		},
		{
			field: 'GPM',
			cellClass: 'decimal',
			headerName: 'GPM',
			cellRenderer: (p) => p.value?.toFixed(2),
			minWidth: 90,
			aggFunc: 'sum',
			filter: 'agSetColumnFilter',
			filterParams: decimalFilterParams,
		},
	]);

	const {
		isLoading: summaryDataLoading,
		serverError: summaryDataError,
		apiData: summaryData,
	} = useFetch(
		`https://internal.supremecomponents.com/api/externalgateway.php?route=getSalesSummary&user=${cascaderOption}&viewas=${
			cascaderDesignation ? cascaderDesignation : userType
		}`,
		'customer-report-summary-data'
	);

	const getData = async () => {
		setIsLoading(true);
		try {
			const [customerReportResponse, mpnReportResponse] = await Promise.all([
				axios.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=getCustomerDataTable&viewas=${
						cascaderDesignation ? cascaderDesignation : userType
					}&user=${cascaderOption}`
				),
				axios.get(
					`https://internal.supremecomponents.com/api/externalgateway.php?route=getMPNDataTable&type=billing&viewas=${
						cascaderDesignation ? cascaderDesignation : userType
					}&user=${cascaderOption}&fy=2025`
				),
			]);

			const customerReportData = customerReportResponse.data;
			const mpnReportData = mpnReportResponse.data;

			// Process customer report data
			const updatedData = customerReportData?.Data?.map((customer) => {
				// Create a comparison-friendly customer name without '<Released>'
				const comparisonCustomerName = customer.Customer.replace(
					' <Released>',
					''
				).toUpperCase(); // Ensure case-insensitivity

				const rep = customer.SiSalesRep.toUpperCase(); // Ensure case-insensitivity

				// Calculate no_of_lines_billed only if both Customer and SalesRep match
				const noOfLinesBilled = mpnReportData?.Data?.filter(
					(mpn) =>
						mpn.Customer.toUpperCase() === comparisonCustomerName &&
						mpn.SalesRep.toUpperCase() === rep
				).length;

				customer.no_of_lines_billed = noOfLinesBilled;

				// Calculate the % lines Converted
				if (customer.no_of_lines === 0 || noOfLinesBilled === 0) {
					customer.no_of_per_lines_converted = 0;
				} else {
					const conversionPercentage = (
						(noOfLinesBilled / customer.no_of_lines) *
						100
					).toFixed(2);
					customer.no_of_per_lines_converted = parseFloat(conversionPercentage);
				}

				return customer;
			});

			const updatedCustomerReport = {
				...customerReportData,
				Data: updatedData,
			};

			setData(updatedCustomerReport);
			setError(null);
		} catch (err) {
			setError(err.message);
			setData([]);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getData(); // eslint-disable-next-line
	}, [cascaderDesignation, userType, cascaderOption]);

	const dataWithFlags = data?.Data?.filter((d) => {
		return COUNTRY_CODES[d?.Country] === undefined
			? d
			: COUNTRY_CODES[d?.Country];
	});

	const GotoMpnReport = () => {
		return (
			<span>
				The data in the summary card above reflects your true billings and
				backorder data. For a detailed breakdown, look at the&nbsp;
				<span
					onClick={() => navigate('/mpn-report')}
					className={styles['link']}
				>
					MPN report
				</span>
				.
			</span>
		);
	};

	const onGridReady = (params) => {
		setGridApi(params.api);

		const updateColumns = () => {
			const columnApi = params.columnApi;
			const today = new Date();
			const month = today.getMonth(); // getMonth() returns 0-11

			// Hide all columns initially
			columnApi.setColumnsVisible(['FyQ1', 'FyQ2', 'FyQ3', 'FyQ4'], false);

			// Determine the quarters to show based on the month
			if (month >= 3 && month <= 5) {
				// April-June
				columnApi.setColumnVisible('FyQ1', true);
			} else if (month >= 6 && month <= 8) {
				// July-September
				columnApi.setColumnsVisible(['FyQ1', 'FyQ2'], true);
			} else if (month >= 9 && month <= 11) {
				// October-December
				columnApi.setColumnsVisible(['FyQ1', 'FyQ2', 'FyQ3'], true);
			} else if (month >= 0 && month <= 2) {
				// January-March
				columnApi.setColumnsVisible(['FyQ1', 'FyQ2', 'FyQ3', 'FyQ4'], true);
			}
		};

		updateColumns();

		const calculateFooterValue = () => {
			let fyPreviousTotal = 0;
			let fyCurrentTotal = 0;
			let percentOfTotalBillingsTotal = 0;
			let fyq1Total = 0;
			let fyq2Total = 0;
			let fyq3Total = 0;
			let fyq4Total = 0;
			let backorderTotal = 0;
			let GPMTotal = 0;
			let GPMCount = 0;
			let rfqTotal = 0;
			let rfqQ1Total = 0;
			let rfqQ2Total = 0;
			let rfqQ3Total = 0;
			let rfqQ4Total = 0;

			params.api.forEachNodeAfterFilter(function (node) {
				fyPreviousTotal += node.data.FyPrevious;
				fyCurrentTotal += node.data.FyCurrent;
				percentOfTotalBillingsTotal += node.data.PercentOfTotalBillings;
				fyq1Total += node.data.FyQ1;
				fyq2Total += node.data.FyQ2;
				fyq3Total += node.data.FyQ3;
				fyq4Total += node.data.FyQ4;
				backorderTotal += node.data.Backorder;
				rfqTotal += node.data.RfqTotal;
				rfqQ1Total += node.data.RfqQ1;
				rfqQ2Total += node.data.RfqQ2;
				rfqQ3Total += node.data.RfqQ3;
				rfqQ4Total += node.data.RfqQ4;
				const GPM = node.data.GPM;
				if (GPM > 0) {
					GPMTotal += GPM;
					GPMCount++;
				}
			});

			// average for GPM column
			const averageGPM = GPMCount > 0 ? GPMTotal / GPMCount : 0;

			const footerData = [
				{
					SalesRep: 'Total:',
					Customer: '',
					CompanyType: '',
					FyPrevious: fyPreviousTotal,
					FyCurrent: fyCurrentTotal,
					PercentOfTotalBillings: `${Math.round(percentOfTotalBillingsTotal)}`,
					FyQ1: fyq1Total,
					FyQ2: fyq2Total,
					FyQ3: fyq3Total,
					FyQ4: fyq4Total,
					Backorder: backorderTotal,
					GPM: averageGPM,
					RfqTotal: rfqTotal,
					RfqQ1: rfqQ1Total,
					RfqQ2: rfqQ2Total,
					RfqQ3: rfqQ3Total,
					RfqQ4: rfqQ4Total,
				},
			];
			params.api.setPinnedBottomRowData(footerData);
		};
		calculateFooterValue();

		params.api.addEventListener('filterChanged', calculateFooterValue);
	};

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			floatingFilter: true,
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	const autoGroupColumnDef = useMemo(() => {
		return {
			minWidth: 200,
			filter: 'agGroupColumnFilter',
		};
	}, []);

	const excelStyles = useMemo(() => {
		return [
			{
				id: 'numberType',
				numberFormat: {
					format: '$ #,##0.00',
				},
				alignment: {
					horizontal: 'Distributed',
				},
			},
			{
				id: 'decimal',
				numberFormat: {
					format: '0.00',
				},
				alignment: {
					horizontal: 'Distributed',
				},
			},
			{
				id: 'header',
				font: {
					bold: true,
				},
			},
		];
	}, []);

	//Export to excel
	const exportToExcel = () => {
		const customerReportExcelParams = {
			sheetName: 'Customer Report',
			fileName: 'Customer Report.xlsx',
		};
		gridApi.exportDataAsExcel(customerReportExcelParams);
	};

	function formatNumber(num) {
		// var isNegative = false;
		let returnval = '';
		if (num === undefined) {
			return;
		}
		if (num < 0) {
			// isNegative = true;
			num = num.toString();
			num = num.replace('-', '');
			num = parseFloat(num);
			returnval = '-';
		}
		if (num >= 1000000000) {
			returnval += num;
		} else if (num >= 1000000) {
			returnval += `${(num / 1000000)?.toFixed(2)}M`;
		} else if (num >= 1000) {
			returnval += `${(num / 1000)?.toFixed(2)}K`;
		} else if (num >= 100) {
			returnval += `${(num / 1000)?.toFixed(2)}K`;
		} else {
			returnval += num?.toFixed(2);
		}
		return returnval;
	}

	// Account summary data
	const accountSummaryData = [
		{
			id: 1,
			title: 'FY Revenue',
			value: `$${formatNumber(summaryData?.FyRevenue)}`,
			toolTip: 'Billings in the current FY',
		},
		{
			id: 2,
			title: 'FY Backorders',
			value: `$${formatNumber(summaryData?.FyBackorder)}`,
			toolTip: 'Backorders in the current FY',
		},
		{
			id: 3,
			title: 'FY Target',
			value: `$${formatNumber(summaryData?.FyTarget)}`,
			toolTip: 'FY Targets set for either you or your team',
		},
		{
			id: 4,
			title: 'FY GAP',
			value: `$${formatNumber(summaryData?.FyGap)}`,
			toolTip: `Calculated as the difference between you or your team's FY billings from you or your team's cumulated sales targets`,
		},
		{
			id: 5,
			title: 'FY %-to-Goal',
			value: `${Math.round(summaryData?.FyPercentToGoal)}%`,
			toolTip:
				userType === 'sr'
					? `The percentage that you attained for this FY.`
					: userType === 'ceo' || userType === 'admin' || userType === 'sm'
					? `The percentage that you or your team has attained for this FY.`
					: '',
		},
		{
			id: 6,
			title: 'Total Accounts',
			value: data?.TotalAccounts?.toLocaleString('en-US'),
			toolTip: `Total accounts assigned to you or your team as listed in the CRM`,
		},
		{
			id: 7,
			title: '# of Billed Customers',
			value: summaryData?.BilledCustomers?.toLocaleString('en-US'),
			toolTip: '# of unique billed customers in the current FY',
		},
		{
			id: 8,
			title: '# of Backorder Customers',
			value: summaryData?.BackorderCustomers?.toLocaleString('en-US'),
			toolTip: '# of unique customers who have a backorder in the current FY',
		},
		{
			id: 9,
			title: '# of Dead Accounts',
			value: summaryData?.DeadAccounts?.toLocaleString('en-US'),
			toolTip:
				'Calculated as your Billed and Backorder customers subtracted from your total accounts',
		},
		{
			id: 10,
			title: 'Recent Billed Accounts',
			value: summaryData?.RecentlyBilled,
			toolTip:
				'Recently billed accounts are those you have billings for within 90 days. Go to the MPN report to filter by date range',
		},
		{
			id: 11,
			title: 'QTR Revenue',
			value: `$${formatNumber(summaryData?.QtrRevenue)}`,
			toolTip: `You or your team's quarterly billings in the current FY`,
		},
		{
			id: 12,
			title: 'QTR GAP-to-Goal',
			value: `$${formatNumber(summaryData?.QtrGapToGoal)}`,
			toolTip: `Calculated as you or your team's Quarter billings subtracted from the FY Quarter target`,
		},
		{
			id: 13,
			title: 'QTR %-to-Goal',
			value: `${Math.round(summaryData?.QtrPercentToGoal)}%`,
			toolTip:
				userType === 'sr'
					? `The percentage needed for you to achieve your goal for the current quarter`
					: userType === 'sm'
					? `The percentage needed for your team to achieve your goal for the current quarter`
					: userType === 'ceo' || userType === 'admin'
					? `The percentage needed for SCI to achieve the goal for the current quarter`
					: '',
		},
		// {
		// 	id: 14,
		// 	title: "Previous FY",
		// 	value: `$${formatNumber(summaryData?.PrevFY)}`,
		// 	toolTip: `You or your team's billings in the Previous FY`,
		// },
	];

	// Clipboard
	const summaryContent = useRef(null);
	const copyToClipboard = async () => {
		try {
			const text = summaryContent.current.innerText;
			await navigator.clipboard.writeText(text);
			message.open({
				type: 'success',
				content: 'Copied to clipboard',
			});
		} catch (err) {
			message.open({
				type: 'error',
				content: `Failed to copy text`,
			});
		}
	};

	// Cascader options
	const filterAndMapSalesReps = (salesReps) => {
		return salesReps
			.filter((rep) => rep?.isActive)
			.map((rep) => ({
				value: rep?.SalesRepEmail,
				label: rep?.SalesRepName,
				designation: rep?.Designation,
				disabled: rep?.isActive === false,
				children: rep?.SalesTeam ? filterAndMapSalesReps(rep.SalesTeam) : [],
			}));
	};

	let cascaderOptions;
	if (userType === 'admin' || userType === 'ceo') {
		cascaderOptions = filterAndMapSalesReps(userInfo?.ViewAsAdmin || []);
	}

	if (userType === 'sm') {
		cascaderOptions = filterAndMapSalesReps(userInfo?.ViewAsSM || []);
	}

	// For cascader
	const onChange = (value, selectedOptions) => {
		let designation =
			selectedOptions[selectedOptions.length - 1].designation.toLowerCase();
		const salesRepname =
			selectedOptions[selectedOptions.length - 1].label.toLowerCase();
		const containSelf = salesRepname?.includes('self');

		if (
			selectedOptions[selectedOptions.length - 1].value ===
			'piyush@supremecomponents.com'
		) {
			designation = 'sm';
		}

		if (containSelf === true) {
			designation = 'sr';
		}

		if (designation === 'sales rep') {
			designation = 'sr';
		}
		if (designation === 'sales manager') {
			designation = 'sm';
		}

		let mail = value[value.length - 1];
		setCascaderDesignation(designation);
		setCascaderOption(mail);
	};

	// on clear cascader
	const clearCascader = () => {
		setCascaderDesignation(userType);
		setCascaderOption(me?.mail);
	};

	// Find table data
	const quickFind = (e) => {
		gridApi.setQuickFilter(e.target.value);
	};

	const context = useMemo(() => {
		return {
			COUNTRY_CODES: COUNTRY_CODES,
		};
	}, []);

	const getRowStyle = useCallback((params) => {
		if (params.node.rowPinned) {
			return { fontWeight: 600, fontSize: '14px' };
		}
	}, []);

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.ctrlKey && event.shiftKey && event.key === 'L') {
				gridApi.setFilterModel(null);
				gridApi?.setQuickFilter('');
			}
		};

		window.addEventListener('keydown', handleKeyPress);

		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [gridApi]);

	return (
		<div className={styles['customer-report-wrapper']}>
			{/* <NotificationBar message={<Notification />} /> */}
			<BreadCrumb
				route1="Sales Performance"
				route2="Customer Report"
				icon={<LineChartOutlined style={{ paddingRight: '5px' }} />}
			/>
			<InnerHeader
				exportToExcel={exportToExcel}
				lastReport={data?.LastUpdated}
				// exportMontlyPerf
			/>
			<div className={styles['customer-report']}>
				{userType !== 'sr' && (
					<div className={styles['dropdown-wrapper']}>
						<Cascader
							placeholder="SR, SM"
							options={cascaderOptions}
							onChange={onChange}
							onClear={clearCascader}
							changeOnSelect={true}
							disabled={summaryDataLoading || isLoading}
							title="Drill down by:"
						/>
					</div>
				)}

				<AccountSummaryCard>
					{summaryDataError && !summaryDataLoading && (
						<div className={styles['error-summary-data']}>
							<ErrorMessage
								style={{ width: '200px' }}
								message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed."
							/>
						</div>
					)}

					{summaryDataLoading && (
						<div className={styles['loading-summary-data']}>
							<LoadingMessage height={40} width={40} />
						</div>
					)}

					{!summaryDataLoading &&
						!summaryDataError &&
						summaryData?.Success === true && (
							<div className={styles['card-data']}>
								<ToolTip title="Copy data to clipboard" placement="bottomRight">
									<MdContentCopy
										onClick={copyToClipboard}
										className={styles['copy-icon']}
									/>
								</ToolTip>
								<div ref={summaryContent} className={styles['summary-data']}>
									{accountSummaryData.map((el) => (
										<div key={el.id} className={styles['data-wrapper']}>
											<ToolTip title={el.toolTip} trigger="click">
												<p className={styles['title']}>{el.title}:</p>
											</ToolTip>
											<p className={styles['value']}>{el.value}</p>
										</div>
									))}
								</div>
							</div>
						)}

					{summaryData?.Success === false &&
						!summaryDataLoading &&
						!summaryDataError && (
							<div className={styles['no-data-summary-wrapper']}>
								<img
									src={NoDataImage}
									alt="nodata"
									className={styles['no-data-image']}
								/>
							</div>
						)}
				</AccountSummaryCard>

				<NotificationBar message={<GotoMpnReport />} wraningBar />

				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="That’s a huge dataset we’ve got here. Give us a moment as we retrieve everything and organize them for you." />
					</div>
				)}

				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{data?.Success === false && !isLoading && !error && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Oops! Seems like none of your assigned accounts from the CRM have a transaction. What could be worse? You have not been assigned any accounts at all." />
					</div>
				)}

				{!isLoading && !error && data?.Success === true && (
					<>
						<div className={styles['table-info']}>
							<TableDataCountInput
								transactions={`Showing ${
									gridApi
										?.getModel()
										?.rootNode.childrenAfterFilter.length?.toLocaleString(
											'en-US'
										) || 0
								} transactions`}
								onChange={quickFind}
								allowClear
							/>
						</div>

						<div className={`ag-theme-custom ${styles['table-wrapper']} `}>
							<>
								{!localStorage.getItem('customer-report-table-data') ? (
									<div className={styles['table-tooltip-notification']}>
										<TabelToolTipNotification title="Hover and hold your cursor over any column header to see a description" />
									</div>
								) : (
									''
								)}
								<AgGridReact
									ref={gridRef}
									onGridReady={onGridReady}
									rowData={dataWithFlags}
									columnDefs={columnsDefs}
									context={context}
									defaultColDef={defaultColDef}
									autoGroupColumnDef={autoGroupColumnDef}
									animateRows={true}
									pagination={true}
									floatingFilter={true}
									tooltipShowDelay={1500}
									excelStyles={excelStyles}
									enableRangeSelection={true}
									paginationPageSizeSelector={false}
									getRowStyle={getRowStyle}
								/>
							</>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default CustomerReport;
