import { useEffect, useMemo, useState } from 'react';
import { GrStorage } from 'react-icons/gr';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import { Progress } from 'antd';

import BreadCrumb from '../../../components/breadcrumb';
import SectionInfo from '../../../components/sectionInfo';
import LoadingMessage from '../../../components/loaderMessage';
import ErrorMessage from '../../../components/errorMessage';
import { isNotEmptyArray } from '../../../utils';
import NoData from '../../../components/nodata';

import styles from './mailBoxStorageDetails.module.scss';

const MailBoxStorageDetails = () => {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [columnDefs] = useState([
		{ field: 'User Principal Name', headerName: 'User Principal Name' },
		{ field: 'Display Name', headerName: 'Display Name' },
		{ field: 'Storage Used (GB)', headerName: 'Storage Used (GB)' },
		{ field: 'Quota (GB)', headerName: 'Quota (GB)' },
		{
			field: 'Usage (%)',
			headerName: 'Usage (%)',
			cellRenderer: (p) => (
				<Progress
					status={p.value < 100 ? 'normal' : p.value > 80 ? 'exception' : ''}
					percent={p.value}
					size="small"
					format={(percent, successPercent) => `${percent}%`}
				/>
			),
		},
	]);

	const getData = () => {
		setIsLoading(true);
		axios
			.get(
				`https://internal.supremecomponents.com/api/externalgateway.php?route=mailvault_get_usage_for_org`
			)
			.then((response) => {
				setData(response.data);
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getData();
	}, []);

	const defaultColDef = useMemo(
		() => ({
			sortable: true,
			resizable: true,
			floatingFilter: true,
			suppressMovable: true,
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							applyMiniFilterWhileTyping: true,
						},
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
							applyMiniFilterWhileTyping: true,
						},
					},
				],
			},
		}),
		[]
	);

	return (
		<div className={styles['wrapper']}>
			<BreadCrumb
				route1="MailBox Storage Details"
				icon={<GrStorage style={{ fontSize: '20px' }} />}
			/>
			<SectionInfo
				removeshortcutkeys
				title="MailBox Storage Details"
				info="MailBox Storage Details"
			/>

			<div className={styles['mail-storage']}>
				{isLoading && (
					<div className={styles['loading-wrapper']}>
						<LoadingMessage message="Loading..." />
					</div>
				)}
				{error && !isLoading && (
					<div className={styles['error-wrapper']}>
						<ErrorMessage message="Snap! That sound could be that of your data endpoint going kaput. But don’t worry our engineers are already on it getting it fixed." />
					</div>
				)}

				{!isNotEmptyArray(data) && !error && !isLoading && (
					<div className={styles['no-data-wrapper']}>
						<NoData message="Its all empty here. Select a date range." />
					</div>
				)}

				{!isLoading && !error && isNotEmptyArray(data) && (
					<>
						<div className={`ag-theme-custom ${styles['table-wrapper']} `}>
							<AgGridReact
								rowData={data}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								animateRows={true}
								pagination={true}
								paginationPageSizeSelector={false}
								enableCellChangeFlash={true}
								enableRangeSelection={true}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default MailBoxStorageDetails;
