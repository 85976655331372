import { useContext, useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { RiDeleteBin6Fill } from 'react-icons/ri';
import { Divider, message, Tag } from 'antd';
import { MdModeEdit } from 'react-icons/md';
import { IoEye } from 'react-icons/io5';
import { LuDownload } from 'react-icons/lu';
import { Link } from 'react-router-dom';
import { HiOutlineExternalLink } from 'react-icons/hi';

import Popconfirm from '../../../../components/popConfirm';
import ToolTip from '../../../../components/tooltip';
import FranchisedBrandsContext from '../../../../context/franchisedBrands';
import Modal from '../../../../components/modal';
import BgImage from '../../../../images/franchised-bg-image.jpg';
import PlaceholderImage from '../../../../images/frachised-logo-placeholder.png';
import AddFranchisedBrandForm from '../From';
import { API_BASE_URL } from '../../../../config/baseURLs';
import { COUNTRY_CODES } from '../../../../columns';

import styles from './actions.module.scss';

const Actions = (p) => {
	const { getData, setFormValues } = useContext(FranchisedBrandsContext);

	const [popup, setpPopup] = useState(false);
	const [modal, setModal] = useState(false);
	const [drawer, setDrawer] = useState(false);
	const [data, setData] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const closePopup = () => {
		setpPopup(false);
	};

	const openPopUp = () => {
		setpPopup(true);
	};

	const openDrawer = () => {
		setDrawer(true);
		setIsLoading(true);
		axios
			.get(`${API_BASE_URL}/franchised/${p.data.id}`)
			.then((response) => {
				const apiData = response.data?.data;
				setData(response.data?.data);
				setFormValues({
					companyLogo: apiData?.companyLogo && apiData?.companyLogo,
					authorizationLetter:
						apiData?.authorizationLetter && apiData?.authorizationLetter,
					corpPresentation:
						apiData?.corporatePresentation && apiData?.corporatePresentation,
					sortDescription:
						apiData?.shortDescription && apiData?.shortDescription,
					longDescription: apiData.longDescription && apiData?.longDescription,
					headquaters:
						apiData?.headquarters && JSON.parse(apiData?.headquarters),
					categories: apiData?.categories && JSON.parse(apiData?.categories),
					productCategories:
						apiData?.productCategories &&
						JSON.parse(apiData?.productCategories),
				});
				setError(null);
			})
			.catch((err) => {
				setError(err);
				setData(null);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const initialValues = {
		companyName: data?.companyName,
		websiteUrl: data?.websiteUrl,
		companyLogo: data?.companyLogo,
		displayonUI: data?.displayonUI,
		displayInRegions: data?.displayInRegions,
		shortDescription: data?.shortDescription,
		longDescription: data?.longDescription,
		manufacturersWebsite: data?.manufacturersWebsite,
		automotive: data?.automotive,
		categories: data?.categories && JSON.parse(data?.categories),
		productCategories:
			data?.productCategories && JSON.parse(data?.productCategories),
		resources: data?.resources?.map((resource) => ({
			resourceName: resource.resourceName,
			resourceFile: {
				name: resource.resourceFile.split('/').pop(),
				url: resource.resourceFile,
			},
		})),
	};

	const deleteQueue = (id) => {
		axios
			.delete(`${API_BASE_URL}/franchised/${id}`)
			.then((response) => {
				setpPopup(false);
				getData();

				message.success(response.data?.message);
			})
			.catch(() => {
				message.error('Something went wrong please try again later!');
			});
	};

	const headQuarters = p.data?.headquarters && JSON.parse(p.data?.headquarters);

	return (
		<div className={styles['wrapper']}>
			<div className={styles['icons']}>
				<Popconfirm
					placement="bottomLeft"
					okButtonColor="red"
					open={popup}
					title="Delete"
					description="You cannot undo this action. Do you confirm the deletion of the selected row?"
					okText="Yes"
					cancelText="Cancel"
					onConfirm={() => deleteQueue(p.data?.id)}
					onCancel={closePopup}
					icon={
						<QuestionCircleOutlined
							style={{
								color: 'red',
							}}
						/>
					}
				>
					<ToolTip title="Delete">
						<RiDeleteBin6Fill
							style={{
								fontSize: '18px',
								cursor: 'pointer',
								color: '#C00000',
								display: 'flex',
								alignItems: 'center',
							}}
							onClick={openPopUp}
						/>
					</ToolTip>
				</Popconfirm>

				<ToolTip title="Edit">
					<MdModeEdit
						style={{ fontSize: '18px', cursor: 'pointer' }}
						onClick={openDrawer}
					/>
				</ToolTip>
				<ToolTip title="View Details">
					<IoEye
						style={{ fontSize: '18px', cursor: 'pointer' }}
						onClick={() => setModal(!modal)}
					/>
				</ToolTip>

				{drawer && (
					<AddFranchisedBrandForm
						drawer={drawer}
						setDrawer={setDrawer}
						isLoading={isLoading}
						data={data}
						setData={setData}
						dataError={error}
						initialValues={initialValues}
					/>
				)}

				{modal && (
					<Modal
						title={
							<>
								<div className={styles['modal-header-wrapper']}>
									<span>{p.data.companyName}</span>
									<div className={styles['right-side']}>
										<div className={styles['flag']}>
											<img
												style={{ width: '15px' }}
												src={`https://flags.fmcdn.net/data/flags/mini/${
													COUNTRY_CODES[headQuarters?.name] || 'default'
												}.png`}
												alt={data?.name || 'No name'}
											/>

											<span>{headQuarters?.name}</span>
										</div>
										{p.data.displayonUI === true ? (
											<Tag color="green">Enabled</Tag>
										) : (
											<Tag color="red">Disabled</Tag>
										)}

										<Tag color="blue">{p.data?.displayInRegions}</Tag>
									</div>
								</div>
								<Divider
									style={{
										margin: '5px 0',
										borderBlockStart: '1px solid rgb(216, 216, 216)',
									}}
								/>
							</>
						}
						open={modal}
						onCancel={() => setModal(!modal)}
						destroyOnClose
						width="80%"
					>
						<div className={styles['franchised-details-wrapper']}>
							<img
								className={styles['banner-image']}
								src={BgImage}
								alt="Background"
							/>
							<div className={styles['upper-section']}>
								<div className={styles['logo-name-wrapper']}>
									<div className={styles['logo-wrapper']}>
										<img
											className={styles['logo']}
											src={p.data?.companyLogo || PlaceholderImage}
											alt={p.data?.companyName || 'Logo'}
										/>
									</div>
									<div className={styles['company-name-link']}>
										<p className={styles['company-name']}>
											{p.data?.companyName}
										</p>
										<Link to={p.data?.manufacturersWebsite} target="_blank">
											<HiOutlineExternalLink className={styles['link']} />
										</Link>
									</div>
								</div>
								{(p.data?.authorizationLetter ||
									p.data?.corporatePresentation) && (
									<div className={styles['files-wrapper']}>
										{p.data?.authorizationLetter && (
											<Link
												className={styles['link']}
												to={p.data?.authorizationLetter}
												target="_blank"
											>
												<LuDownload className={styles['icon']} />
												<span>Download Authorization Letter</span>
											</Link>
										)}
										{p.data?.corporatePresentation && (
											<Link
												className={styles['link']}
												to={p.data?.corporatePresentation}
												target="_blank"
											>
												<LuDownload className={styles['icon']} />
												<span>Download Corporate Presentation</span>
											</Link>
										)}
									</div>
								)}
							</div>
							<div className={styles['bottom-section']}>
								<div className={styles['description-wrapper']}>
									<p className={styles['title']}>Sort Description</p>
									<div
										className={styles['description']}
										dangerouslySetInnerHTML={{
											__html: p.data.shortDescription,
										}}
									/>
								</div>
								<div className={styles['description-wrapper']}>
									<p className={styles['title']}>Long Description</p>
									<div
										className={styles['description']}
										dangerouslySetInnerHTML={{
											__html: p.data.longDescription,
										}}
									/>
								</div>
								{p.data?.productCategories && (
									<div className={styles['description-wrapper']}>
										<p className={styles['title']}>Product Type</p>
										<div>
											{p.data?.productCategories &&
												JSON.parse(p.data?.productCategories).map(
													(el, index) => (
														<Tag color="#001433" key={index}>
															{el}
														</Tag>
													)
												)}
										</div>
									</div>
								)}
								{p.data?.categories && (
									<div className={styles['description-wrapper']}>
										<p className={styles['title']}>Product Categories</p>
										<div>
											{p.data?.categories &&
												JSON.parse(p.data?.categories).map((el, index) => (
													<Tag color="#001433" key={index}>
														{el}
													</Tag>
												))}
										</div>
									</div>
								)}
							</div>
						</div>
					</Modal>
				)}
			</div>
		</div>
	);
};

export default Actions;
