import {
	Col,
	Form,
	message,
	Row,
	Upload,
	Radio,
	Checkbox,
	Divider,
} from 'antd';
import { useContext, useMemo, useRef, useState } from 'react';
import JoditEditor from 'jodit-react';
import { IoMdAttach } from 'react-icons/io';
import axios from 'axios';
import { LoadingOutlined, MinusCircleOutlined } from '@ant-design/icons';

import Input from '../../../../components/input';
import Button from '../../../../components/button';
import UploadedFile from '../../../../components/uploadedFile';
import { useFetch } from '../../../../hooks/useFetch';
import Select from '../../../../components/select';
import Drawer from '../../../../components/drawer';
import FranchisedBrandsContext from '../../../../context/franchisedBrands';
import { API_BASE_URL } from '../../../../config/baseURLs';
import { COUNTRY_CODES } from '../../../../columns';

import styles from './form.module.scss';

const AddFranchisedBrandForm = ({
	drawer,
	setDrawer,
	initialValues,
	isLoading,
	data,
	dataError,
	setData,
}) => {
	const { getData, formValues, setFormValues, extractFileName } = useContext(
		FranchisedBrandsContext
	);

	const [formData, setFormData] = useState(null);
	const [loadingDescription, setLoadingDescription] = useState(false);

	const formatBrandName = (name) => {
		return name
			.toLowerCase() // Convert to lowercase for consistency
			.replace(/[^a-z0-9+()]+/g, '-') // Replace non-alphanumeric characters (except `+` and `()`) with hyphens
			.replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
	};

	const sortDescription = useRef(null);
	const {
		data: headQuaters,
		loading,
		error,
	} = useFetch(
		'https://countriesnow.space/api/v0.1/countries/info?returns=currency%2Cflag%2CunicodeFlag%2Cdialcode'
	);

	const [disabled, setDisabled] = useState(false);

	const closeDrawer = () => {
		setDrawer(false);
		form.resetFields();
		setDisabled(false);
		// setData({});
		setFormValues({
			...formValues,
			companyLogo: null,
			authorizationLetter: null,
			// corpPresentation: null,
			sortDescription: '',
			longDescription: '',
			headquaters: null,
			categories: null,
		});
	};

	const [form] = Form.useForm();

	const onFinish = (values) => {
		setDisabled(true);
		const formData = new FormData();

		formData.append('companyName', values.companyName);
		formData.append('websiteUrl', values.websiteUrl);
		if (formValues.companyLogo) {
			formData.append('companyLogo', formValues.companyLogo);
		}
		formData.append(
			'authorizationLetter',
			formValues.authorizationLetter || null
		);
		values.resources.forEach((resource, index) => {
			formData.append(
				`resources[${index}][resourceName]`,
				resource.resourceName || ''
			);
			formData.append(
				`resources[${index}][resourceFile]`,
				resource.resourceFile?.url
					? resource.resourceFile?.url
					: resource.resourceFile.file || null
			);
		});
		// formData.append(
		// 	'corporatePresentation',
		// 	formValues.corpPresentation || null
		// );
		formData.append('displayonUI', values.displayonUI);
		formData.append('displayInRegions', values.displayInRegions);
		formData.append('automotive', values.automotive);
		formData.append(
			'productCategories',
			(values.productCategories?.length > 0 &&
				JSON.stringify(values.productCategories)) ||
				null
		);
		formData.append(
			'categories',
			(values.categories?.length > 0 && JSON.stringify(values.categories)) ||
				null
		);
		formData.append(
			'headquarters',
			JSON.stringify(formValues.headquaters) || null
		);
		formData.append('shortDescription', values.shortDescription);
		formData.append('longDescription', values.longDescription);
		formData.append('manufacturersWebsite', values.manufacturersWebsite);
		formData.append('queryBrandName', formatBrandName(values.companyName));

		axios({
			method: data?.id ? 'put' : 'post',
			url: data?.id
				? `${API_BASE_URL}/franchised/${data.id}`
				: `${API_BASE_URL}/franchised`,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
			.then((response) => {
				getData();
				message.success(response.data?.message);
			})
			.catch((err) => {
				message.error('Something Went Wrong!');
			})
			.finally(() => {
				setDrawer(false);
				form.resetFields();
				setDisabled(false);
				setFormValues({
					companyLogo: null,
					authorizationLetter: null,
					corpPresentation: null,
					shortDescription: '',
					longDescription: '',
					headquarters: null,
				});
			});
	};

	const generateDescriptions = async () => {
		if (!formData?.companyName || !formData?.websiteUrl) {
			message.error('Please enter the Company Name and Website URL.');
			return;
		}

		try {
			setLoadingDescription(true);

			const shortDataUrl = `https://internal.supremecomponents.com/api/externalgateway.php?route=sciwebsite_get_brand_data&company=${formData?.companyName}&website=${formData?.websiteUrl}&type=short`;
			const longDataUrl = `https://internal.supremecomponents.com/api/externalgateway.php?route=sciwebsite_get_brand_data&company=${formData?.companyName}&website=${formData?.websiteUrl}&type=long`;

			const [shortDataResponse, longDataResponse] = await Promise.all([
				axios.get(shortDataUrl),
				axios.get(longDataUrl),
			]);

			const shortData = shortDataResponse.data?.html || '';
			const longData = longDataResponse.data?.html || '';

			form.setFieldsValue({
				shortDescription: shortData,
				longDescription: longData,
			});

			setLoadingDescription(false);
		} catch (error) {
			setLoadingDescription(false);
			message.error('Error loading descriptions. Please enter them manually.');
		}
	};

	const companyLogoProps = {
		name: 'company_logo',
		action: '',
		fileList: [],
		showUploadList: false,
		maxCount: 1,
		beforeUpload: (file) => {
			const fileSize = file.size;
			const isImage = file.name.split('.').pop().toLowerCase();
			const validExtensions = ['png', 'jpg', 'jpeg', 'webp', 'svg'];

			if (!validExtensions.includes(isImage)) {
				message.error('Please select a image');
				return false;
			}

			if (fileSize > 5242880) {
				// file size in bytes (1MB = 1,048,576 bytes)
				message.error('The file cannot be more than 5MB');
				return false;
			}

			setFormValues({ ...formValues, companyLogo: file });
			return false;
		},
		customRequest: () => null,
	};

	const resourcesFileProps = {
		name: 'resourceFile',
		action: '',
		fileList: [],
		showUploadList: false,
		maxCount: 1,
		beforeUpload: (file) => {
			const fileSize = file.size;

			if (fileSize > 5242880) {
				// file size in bytes (1MB = 1,048,576 bytes)
				message.error('The file cannot be more than 5MB');
				return false;
			}

			return false;
		},
		customRequest: () => null,
	};

	const authorizationLetterProps = {
		name: 'company_file',
		action: '',
		fileList: [],
		showUploadList: false,
		maxCount: 1,
		beforeUpload: (file) => {
			const fileSize = file.size;
			const isPDF = file.type;

			if (isPDF !== 'application/pdf') {
				message.error('Please select a PDF');
				return false;
			}

			// file size in bytes (1MB = 1,048,576 bytes)
			if (fileSize > 5242880) {
				message.error('The file cannot be more than 5MB');
				return false;
			}

			setFormValues({ ...formValues, authorizationLetter: file });
			return false;
		},
		customRequest: () => null,
	};

	// const corpPresentationProps = {
	// 	name: 'company_file',
	// 	action: '',
	// 	fileList: [],
	// 	showUploadList: false,
	// 	maxCount: 1,
	// 	beforeUpload: (file) => {
	// 		const isPPT = file.type;
	// 		const fileSize = file.size;

	// 		if (
	// 			isPPT !==
	// 			'application/vnd.openxmlformats-officedocument.presentationml.presentation'
	// 		) {
	// 			message.error('Please select a PPT');
	// 			return false;
	// 		}

	// 		if (fileSize > 50 * 1024 * 1024) {
	// 			message.error('The file cannot be more than 50MB');
	// 			return false;
	// 		}

	// 		setFormValues({ ...formValues, corpPresentation: file });
	// 		return false;
	// 	},
	// 	customRequest: () => null,
	// };

	const buttons = [
		'bold',
		'underline',
		'italic',
		'|',
		'align',
		'|',
		'ul',
		'ol',
		'|',
		'eraser',
	];

	const config = useMemo(
		() => ({
			readonly: false,
			spellcheck: true,
			language: 'en',
			toolbarButtonSize: 'medium',
			toolbarAdaptive: false,
			showCharsCounter: false,
			showWordsCounter: false,
			showXPathInStatusbar: false,
			askBeforePasteHTML: false,
			askBeforePasteFromWord: false,
			placeholder: 'Add Remarks',
			//defaultActionOnPaste: "insert_clear_html",
			buttons: buttons,
			uploader: {
				insertImageAsBase64URI: true,
			},
			width: '100%',
			disabled: disabled,
			clipboard: {
				match: 'wordHtml', // Options: 'word', 'wordHtml', 'html', 'plain' - match clipboard content type
				processPastedHTML: (html) => {
					// Example: Remove all styles and classes from pasted content
					const doc = new DOMParser().parseFromString(html, 'text/html');
					doc.body.querySelectorAll('*').forEach((el) => {
						el.removeAttribute('style');
						el.removeAttribute('class');
					});
					return doc.body.innerHTML;
				},
				// Additional clipboard settings can be added here
			},
		}), //eslint-disable-next-line
		[]
	);

	const tags = [
		{ label: 'Capacitors', value: 'Capacitors' },
		{ label: 'Crystals', value: 'Crystals' },
		{ label: 'Resistors', value: 'Resistors' },
		{ label: 'Inductors', value: 'Inductors' },
		{ label: 'Optics', value: 'Optics' },
		{ label: 'Transformers', value: 'Transformers' },
		{ label: 'Ferrites', value: 'Ferrites' },
		{ label: 'Switches', value: 'Switches' },
		{ label: 'Fans', value: 'Fans' },
		{ label: 'Relays', value: 'Relays' },
		{ label: 'Cables', value: 'Cables' },
		{ label: 'Connectors', value: 'Connectors' },
		{ label: 'Buzzers/Speakers', value: 'Buzzers/Speakers' },
		{ label: 'Solenoids', value: 'Solenoids' },
		{ label: 'Batteries', value: 'Batteries' },
		{ label: 'Protection', value: 'Protection' },
		{ label: 'Power Supplies', value: 'Power Supplies' },
		{ label: 'Lighting Accessories', value: 'Lighting Accessories' },
		{ label: 'Analog', value: 'Analog' },
		{ label: 'Mixed Signals', value: 'Mixed Signals' },
		{ label: 'MCUs', value: 'MCUs' },
		{ label: 'Diodes', value: 'Diodes' },
		{ label: 'BJT/MOSFET', value: 'BJT/MOSFET' },
		{
			label: 'Modules (IGBT/SCR), Gate Driver',
			value: 'Modules (IGBT/SCR), Gate Driver',
		},
		{ label: 'Displays (LED)', value: 'Displays (LED)' },
		{ label: 'FPGAs', value: 'FPGAs' },
		{ label: 'Memories', value: 'Memories' },
		{ label: 'Optocouplers', value: 'Optocouplers' },
		{ label: 'Sensors', value: 'Sensors' },
		{ label: 'Wireless Modules/Comms', value: 'Wireless Modules/Comms' },
	];

	// Fields

	// Company Name (Mandatory) - added
	// Website URL - added
	// Company Logo - added
	// Short Description - added
	// Long Description - added
	// Authorization Letter (Link to the uploaded file) - Added
	// Corporate Presentation (Link to the uploaded file) - Added
	// Tagging System (Multiple selection field)
	// Parts List
	// Headquarters - added
	// Authorized Countries
	// Enable/Disable (Radio box: Default value Enable) - Added
	// Exclude option (Only for All regions and Japanese) (Values All Regions, Show only in Japan, Remove from Japan) - Added

	return (
		<div className={styles['wrapper']}>
			{drawer && (
				<Drawer
					open={drawer}
					footer={
						<div className={styles['actions']}>
							<Button loading={disabled} onClick={() => form.submit()}>
								Submit
							</Button>
							<Button type="bordered" onClick={closeDrawer}>
								Cancel
							</Button>
						</div>
					}
					title={
						<div className={styles['drawer-header']}>
							{data?.companyName ? (
								<span>
									Your are editing <b>{data?.companyName}</b>
								</span>
							) : (
								'Add Franchised Line'
							)}

							<button
								className={styles['button']}
								onClick={generateDescriptions}
							>
								{' '}
								<span className={styles['button-content']}>
									<svg
										height="14"
										width="14"
										fill="#FFFFFF"
										viewBox="0 0 24 24"
										data-name="Layer 1"
										id="Layer_1"
										class="sparkle"
									>
										<path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
									</svg>
									Generate descriptions{' '}
								</span>
								<div className={styles['container-stars']}>
									<div className={styles['stars']}></div>
								</div>
							</button>
						</div>
					}
					onClose={closeDrawer}
					destroyOnClose
					width={700}
				>
					{isLoading ? (
						<LoadingOutlined />
					) : (
						<div className={styles['form-wrapper']}>
							<Form
								name="add-franchised-brand-form"
								form={form}
								onFinish={onFinish}
								validateTrigger={['onBlur', 'onChange']}
								labelCol={{ span: 24 }}
								requiredMark={false}
								disabled={disabled || loadingDescription}
								className={styles['form']}
								initialValues={initialValues}
								onValuesChange={(changedValues, allValues) => {
									setFormData(allValues);
								}}
							>
								<Row gutter={16}>
									{/* Company Name */}
									<Col sm={24} md={12}>
										<Form.Item
											label="Company Name"
											name="companyName"
											rules={[
												{
													required: true,
													message: 'Please enter Company Name!',
												},
											]}
										>
											<Input placeholder="Company Name" />
										</Form.Item>
									</Col>
									{/* Website URL */}
									<Col sm={24} md={12}>
										<Form.Item
											label="Website URL"
											name="websiteUrl"
											rules={[
												{
													required: true,
													message: 'Please enter Website URL!',
													type: 'url',
												},
											]}
										>
											<Input placeholder="Website URL" />
										</Form.Item>
									</Col>
									{/* Company Logo file */}
									<Col sm={24} md={12}>
										<Form.Item
											label="Company Logo"
											name="companyLogo"
											id="company_logo"
											rules={[
												{
													required: true,
													message: 'Please add Company Logo!',
												},
											]}
										>
											{formValues?.companyLogo ? (
												<UploadedFile
													url={
														formValues?.companyLogo?.name
															? formValues?.companyLogo?.name
															: formValues.companyLogo
													}
													style={{ marginTop: '5px' }}
													fileName={
														formValues?.companyLogo?.name
															? formValues?.companyLogo?.name
															: extractFileName(formValues.companyLogo)
													}
													onRemove={() => {
														setFormValues({
															...formValues,
															companyLogo: null,
														});
														form.setFieldsValue({ companyLogo: null });
														form.validateFields(['companyLogo']);
													}}
													canDownload
												/>
											) : (
												<Upload {...companyLogoProps}>
													<Button type="bordered">
														<IoMdAttach className={styles['attach-icon']} />{' '}
														Upload Company Logo
													</Button>
												</Upload>
											)}
										</Form.Item>
									</Col>
									{/* Authorization Letter file */}
									<Col sm={24} md={12}>
										<Form.Item
											label="Authorization Letter"
											name="authorizationLetter"
											id="authorizationLetter"
										>
											{formValues?.authorizationLetter ? (
												<UploadedFile
													url={
														formValues?.authorizationLetter?.name
															? formValues?.authorizationLetter?.name
															: formValues?.authorizationLetter
													}
													style={{ marginTop: '5px' }}
													fileName={
														formValues?.authorizationLetter?.name
															? formValues?.authorizationLetter?.name
															: extractFileName(formValues?.authorizationLetter)
													}
													onRemove={() => {
														setFormValues({
															...formValues,
															authorizationLetter: null,
														});
														form.setFieldsValue({
															authorizationLetter: null,
														});
													}}
													canDownload
												/>
											) : (
												<Upload {...authorizationLetterProps}>
													<Button type="bordered">
														<IoMdAttach className={styles['attach-icon']} />{' '}
														Upload Authorization Letter
													</Button>
												</Upload>
											)}
										</Form.Item>
									</Col>
									{/* Dynamic files */}{' '}
									<Divider
										style={{
											margin: '5px 0',
											borderBlockStart: '1px solid rgb(216, 216, 216)',
										}}
									/>
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											marginLeft: 8,
										}}
									>
										<div className="font-weight-600 font-18">
											Upload Resources
										</div>
										<div className="font-13 text-lighter pb-2">
											Add and manage the resources that will be accessible to
											users on our website.
										</div>
									</div>
									<Col sm={24} style={{ marginBottom: 10 }}>
										<Form.List name="resources">
											{(fields, { add, remove }) => (
												<div>
													{fields.map((field, index) => (
														<Row
															gutter={16}
															key={index}
															style={{ position: 'relative' }}
														>
															<Col sm={24} md={12}>
																<Form.Item
																	// style={{ marginBottom: 0 }}
																	label="Resource Name"
																	name={[field.name, 'resourceName']}
																	rules={[
																		{
																			required: true,
																			message: 'Please add Resource Name!',
																		},
																	]}
																>
																	<Input placeholder="Resource Name" />
																</Form.Item>
															</Col>
															<Col sm={24} md={12}>
																<Form.Item
																	// style={{ marginBottom: 0 }}
																	label="Resource File"
																	name={[field.name, 'resourceFile']}
																	rules={[
																		{
																			required: true,
																			message: 'Please add Resource File!',
																		},
																	]}
																>
																	{form.getFieldValue('resources')?.[field.name]
																		?.resourceFile ? (
																		<UploadedFile
																			url={
																				form.getFieldValue('resources')[
																					field.name
																				].resourceFile.file?.name ||
																				form.getFieldValue('resources')[
																					field.name
																				].resourceFile.url
																			}
																			fileName={
																				form.getFieldValue('resources')[
																					field.name
																				].resourceFile.file?.name ||
																				extractFileName(
																					form.getFieldValue('resources')[
																						field.name
																					].resourceFile.name
																				)
																			}
																			canDownload={true}
																			onRemove={() => {
																				const resources =
																					form.getFieldValue('resources');
																				resources[field.name].resourceFile =
																					null;
																				form.setFieldsValue({ resources });
																			}}
																		/>
																	) : (
																		<Upload
																			{...resourcesFileProps}
																			beforeUpload={(file) => {
																				const resources =
																					form.getFieldValue('resources');
																				resources[field.name].resourceFile = {
																					name: file.name,
																					url: URL.createObjectURL(file),
																				};
																				form.setFieldsValue({ resources });
																				return false;
																			}}
																		>
																			<Button type="bordered">
																				<IoMdAttach
																					style={{ marginRight: 5 }}
																				/>
																				Upload Resource File
																			</Button>
																		</Upload>
																	)}
																</Form.Item>
															</Col>

															<MinusCircleOutlined
																style={{
																	position: 'absolute',
																	top: 10,
																	right: 10,
																}}
																onClick={() => remove(field.name)}
															/>
														</Row>
													))}
													<Button onClick={() => add()}>+ Add Resource</Button>
												</div>
											)}
										</Form.List>
									</Col>{' '}
									<Divider
										style={{
											margin: '5px 0',
											borderBlockStart: '1px solid rgb(216, 216, 216)',
										}}
									/>
									{/* Corporate Presentation file */}
									{/* <Col sm={24} md={12}>
										<Form.Item
											label="Corporate Presentation"
											name="corporatePresentation"
											id="corporatePresentation"
										>
											{formValues?.corpPresentation ? (
												<UploadedFile
													url={
														formValues?.corpPresentation?.name
															? formValues?.corpPresentation?.name
															: formValues?.corpPresentation
													}
													style={{ marginTop: '5px' }}
													fileName={
														formValues?.corpPresentation?.name
															? formValues?.corpPresentation?.name
															: extractFileName(formValues?.corpPresentation)
													}
													onRemove={() => {
														setFormValues({
															...formValues,
															corpPresentation: null,
														});
														form.setFieldsValue({
															corporatePresentation: null,
														});
													}}
													canDownload
												/>
											) : (
												<Upload {...corpPresentationProps}>
													<Button type="bordered">
														<IoMdAttach className={styles['attach-icon']} />{' '}
														Upload Corporate Presentation
													</Button>
												</Upload>
											)}
										</Form.Item>
									</Col> */}
									{/* Automotive */}
									<Col sm={24} md={12}>
										<Form.Item label="Automotive?" name="automotive">
											<Radio.Group>
												<Radio value={true}>Yes</Radio>
												<Radio value={false}>No</Radio>
											</Radio.Group>
										</Form.Item>
									</Col>
									{/* Display in UI */}
									<Col sm={24} md={12}>
										<Form.Item label="Display on UI?" name="displayonUI">
											<Radio.Group>
												<Radio value={true}>Yes</Radio>
												<Radio value={false}>No</Radio>
											</Radio.Group>
										</Form.Item>
									</Col>
									{/* Display in Regions */}
									<Col sm={24} md={12}>
										<Form.Item
											label="Display in Regions?"
											name="displayInRegions"
										>
											<Radio.Group>
												<Radio value={'Show in All Regions'}>
													Show in All Regions
												</Radio>
												<Radio value={'Show Only in Japan'}>
													Show Only in Japan
												</Radio>
												<Radio value={'Hide in Japan'}>Hide in Japan</Radio>
											</Radio.Group>
										</Form.Item>
									</Col>
									{/* Headquarters */}
									<Col sm={24} md={12}>
										<Form.Item label="Headquarters" name="headquaters">
											{error ? (
												<Input placeholder="Please select Headquarters" />
											) : (
												<Select
													loading={loading}
													allowClear
													showSearch
													placeholder="Select Headquarters"
													type="bordered"
													defaultValue={
														data?.headquarters &&
														JSON.parse(data?.headquarters)?.name
													}
													onChange={(value, options) => {
														setFormValues({
															...formValues,
															headquaters: options?.data,
														});
													}}
													options={headQuaters
														?.sort((a, b) => a.name.localeCompare(b.name))
														?.map((el) => ({
															value: el.name,
															data: {
																...el,
																flagImage: `https://flags.fmcdn.net/data/flags/mini/${
																	COUNTRY_CODES[el?.name]
																}.png`,
															},
															label: (
																<div className={styles['flags']}>
																	<img
																		className={styles['flag']}
																		src={`https://flags.fmcdn.net/data/flags/mini/${
																			COUNTRY_CODES[el?.name]
																		}.png`}
																		alt={el.name}
																	/>
																	<span>{el?.name}</span>
																</div>
															),
														}))}
												/>
											)}
										</Form.Item>
									</Col>
									{/* Manufacturer’s Website */}
									<Col sm={24} md={12}>
										<Form.Item
											label="Manufacturer’s Website"
											name="manufacturersWebsite"
											rules={[
												{
													message: 'Please enter correct URL!',
													type: 'url',
												},
											]}
										>
											<Input placeholder="Manufacturer’s Website" />
										</Form.Item>
									</Col>
									{/* Product Type */}
									<Col sm={24} md={12}>
										<Form.Item
											label="Product Type"
											name="productCategories"
											rules={[
												{
													required: true,
													message: 'Please select Product Type!',
												},
											]}
										>
											<Checkbox.Group>
												<Checkbox value={'Passives'}>Passives</Checkbox>
												<Checkbox value={'Electro-Mechanical'}>
													Electro-Mechanical
												</Checkbox>
												<Checkbox value={'Actives/Semiconductors/ICs'}>
													Actives/Semiconductors/ICs
												</Checkbox>
											</Checkbox.Group>
										</Form.Item>
									</Col>
									{/* Product Categories */}
									<Col sm={24} md={12}>
										<Form.Item
											label="Product Categories"
											name="categories"
											rules={[
												{
													required: true,
													message: 'Please select Product Categories!',
												},
											]}
										>
											<Select
												multiple
												type="bordered"
												placeholder="Please select Product Categories"
												options={tags}
											/>
										</Form.Item>
									</Col>
									{/* Sort description */}
									<Col sm={24}>
										<Form.Item label="Sort Description" name="shortDescription">
											<JoditEditor
												ref={sortDescription}
												config={config}
												onBlur={(content) =>
													setFormValues({
														...formValues,
														sortDescription: content,
													})
												}
											/>
										</Form.Item>
									</Col>
									{/* Long description */}
									<Col sm={24}>
										<Form.Item label="Long Description" name="longDescription">
											<JoditEditor
												ref={sortDescription}
												config={config}
												onBlur={(content) =>
													setFormValues({
														...formValues,
														longDescription: content,
													})
												}
											/>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</div>
					)}
				</Drawer>
			)}
		</div>
	);
};

export default AddFranchisedBrandForm;
